import {
  getProject,
  loadProjectJson,
  updateProject
} from '../../../api'
import unreal from '../../../utils/unreal'

export const saveWorld = async (project, handleSnackbar, setLoading) => {
  setLoading(true)
  try {
    await updateProject(project._id, {
      world_data: {
        ...project.world_data
      }
    })
    handleSnackbar('World saved successfully!', 'success')
  } catch (error) {
    console.error(error)
    handleSnackbar('Failed to save world data.', 'error')
  }
  setLoading(false)
}

export const loadWorld = async (
  {
    iframeRef,
    project,
    setProject,
    PSUrl,
    handleSnackbar,
    setLoading
  }) => {
  setLoading(true)
  try {
    const response = await getProject(project._id)
    if (!response.world_data) {
      // mock world data
      const res = await loadProjectJson()
      response.world_data = { ...res, name: response.project_name }
    }
    if (response) setProject(response)
    unreal.load(iframeRef, PSUrl, response.world_data)
    handleSnackbar('World loaded successfully!', 'success')
  } catch (error) {
    console.error(error)
    handleSnackbar('Failed to load world data.', 'error')
  }
  setLoading(false)
}
