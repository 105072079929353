import React, { useEffect, useRef, useState } from 'react'
import { Box, Slide } from '@mui/material'
import TerminalIcon from '@mui/icons-material/Terminal'
import styles from './Home.module.css'
import {
  fetchWithLoading,
  sendMessage,
  checkMessageStatus,
  retrieveMessages
} from '../../api'
import Footer from '../../components/Footer/Footer'
import DragDrop from '../../components/DragDrop/DragDrop'
import Transition from '../../components/Trasition/Transition'
import { useNavigate } from 'react-router-dom'
import { MESSAGE_STATUS } from '../../constants/messages'
import { generateTextContent } from '../../utils/projects'
import ChatModal from '../../components/ChatModal/ChatModal'
import Console from '../../components/Console/Console'
import BudgetDrawer from '../../components/BudgetDrawer/BudgetDrawer'
import { generateBudget } from '../../mirage/server'
import AssetLibrary from '../../components/AssetLibrary/AssetLibrary'
import RefreshIcon from '@mui/icons-material/Refresh'
import Landing from '../../components/Landing/Landing'
import NavBar from '../../components/NavBar/NavBar'
import config from '../../config'

const { userMenuFlag, homepageUIFlag } = config

export default function Home () {
  const [id, setThreadId] = useState(null)
  const navigate = useNavigate()
  const [project, setProject] = useState(null)
  const [budgetDrawer, setBudgetDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [currentView, setCurrentView] = useState('chat')
  const [dragDropView, setDragDropView] = useState(false)
  const [budget, setBudget] = useState(null)
  const [conversations, setConversations] = useState([])
  const [transitionView, setTransitionView] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [consoleOpen, setConsoleOpen] = useState(false)
  const [assetModalOpen, setAssetModalOpen] = useState(false)
  const [followups, setFollowups] = useState([])
  const containerRef = useRef(null)

  async function submitValue (newPrompt, persona) {
    setConversations(prev => [...prev, {
      role: 'user',
      content: generateTextContent(newPrompt)
    }, {
      role: '',
      content: generateTextContent('Loading...')
    }])

    try {
      setLoading(true)
      const messageResponse = await sendMessage(
        {
          message: newPrompt,
          ...(id && { thread_id: id })
        }
      )

      const threadId = messageResponse?.thread_id

      const response = await checkStatus(threadId)

      if (response.status === MESSAGE_STATUS.completed) {
        if (!id) {
          setThreadId(threadId)
        }
        const projectData = await fetchWithLoading(
          () => retrieveMessages({ threadId }),
          setLoading,
          setError
        )
        setProject(projectData)
        setFollowups(projectData.data[0].content[0].text.followups)
        setConversations(projectData.data.reverse())
      } else if (response.status === MESSAGE_STATUS.failed) {
        const errorMessage = 'Something went wrong. Please retry'
        displayErrorMessage(errorMessage)
        setLoading(false)
        setError(errorMessage)
      }
    } catch (error) {
      console.error('API error:', error)
      displayErrorMessage(error.message)
      setError(error.message)
      setLoading(false)
    }
  }

  const openBudgetDrawer = () => {
    setBudgetDrawer(true)
  }

  const displayErrorMessage = (errorMessage) => {
    setConversations(prevConversations => {
      if (prevConversations.length === 0) return prevConversations
      const newConversations = [...prevConversations]
      const lastElementIndex = newConversations.length - 1
      newConversations[newConversations.length - 1] = {
        ...newConversations[lastElementIndex],
        type: 'error',
        content: generateTextContent(errorMessage)
      }
      return newConversations
    })
  }

  const checkStatus = async (threadId) => {
    try {
      const statusResponse = await checkMessageStatus({ threadId })
      const status = statusResponse.status
      if ([MESSAGE_STATUS.completed, MESSAGE_STATUS.failed].includes(status)) {
        return statusResponse
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000))
        return checkStatus(threadId)
      }
    } catch (error) {
      console.error('Failed to check message status:', error)
      throw error
    }
  }

  useEffect(() => {
    setProject(null)
    const fetchProject = async () => {
      try {
        const res = await fetchWithLoading(
          () => retrieveMessages({ threadId: id }),
          setLoading,
          setError
        )
        setFollowups(res.data[0].content[0].text.followups)
        setConversations(res.data.reverse())
        setProject(res)
      } catch (err) {
        navigate('/')
      }
    }
    if (id) fetchProject()
    else {
      setConversations([])
      setProject(null)
    }
  }, [id, navigate])

  if (dragDropView) {
    return <DragDrop setDragDropView={setDragDropView} />
  }

  if (transitionView) {
    return (
      <Transition
        setTransitionView={setTransitionView}
        setCurrentView={setCurrentView}
      />
    )
  }

  return (
    <>
      <Box
        className={styles.homeContainer}
      >
        {userMenuFlag && <NavBar />}
        {currentView === 'chat' &&
          <Slide
            in
            container={containerRef.current}
            unmountOnExit
            timeout={{
              enter: 0,
              exit: 500
            }}
          >
            <Box
              className={styles.slideContainer}
              sx={{
                padding: homepageUIFlag && { sm: 0, md: '0', lg: '25px 0 0' }
              }}
            >
              <Landing
                setCurrentView={setCurrentView}
                threadId={id}
              />
            </Box>
          </Slide>}

        <Footer />
        <Box
          className={styles.actionButtons}
        >
          <Box
            className={styles.actionButtonIcon}
            sx={{
              backgroundColor: '#4a62d8 !important',
              color: '#ffffff',
              border: '1px solid #293678'
            }}
            onClick={() => setIsModalOpen(true)}
          >
            <img src='/images/ai-inkwell-mini-logo-white.svg' alt='chatbot' />
            <p className={styles.buttonText}>AI Agent</p>
          </Box>
          <Box
            className={styles.actionButtonIcon}
            onClick={openBudgetDrawer}
          >
            <img src='/images/icons/budget.svg' alt='budget' />
            <p className={styles.buttonText}>Budget</p>
          </Box>

          <Box
            className={styles.actionButtonIcon}
            onClick={() => setAssetModalOpen(true)}
          >
            <img src='/images/icons/asset.svg' alt='Asset library' />
            <p className={styles.buttonText}>Assets</p>
          </Box>
          <TerminalIcon
            className={styles.terminal}
            onClick={() => setConsoleOpen(true)}
          />
          {id &&
            <RefreshIcon
              className={styles.refreshIcon}
              onClick={() => {
                navigate('/')
                setThreadId(null)
              }}
            />}
        </Box>
        <ChatModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          currentView={currentView}
          project={project}
          conversations={conversations}
          setCurrentView={setCurrentView}
          setBudget={setBudget}
          loading={loading}
          error={error}
          submitValue={submitValue}
          followups={followups}
          setConversations={setConversations}
        />
      </Box>
      <Console
        open={consoleOpen}
        setOpen={setConsoleOpen}
      />
      <AssetLibrary
        open={assetModalOpen}
        setOpen={setAssetModalOpen}
      />
      <BudgetDrawer
        open={budgetDrawer}
        setOpen={setBudgetDrawer}
        budget={budget || generateBudget()}
        budgetToCompare={generateBudget()}
      />
    </>
  )
}
