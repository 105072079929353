import {
  Avatar,
  Box,
  Button,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  Alert
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../Context/AuthContext'
import { Edit as EditIcon } from '@mui/icons-material'

export default function UserDetails () {
  const { currentUser, updateAuthenticUser } = useAuth()
  const fileInputRef = useRef(null)
  const [selectedFile, setSelectedFile] = useState(null)
  const [preview, setPreview] = useState(null)
  const [finalImage, setFinalImage] = useState(null)
  const [firstName, setFirstName] = useState(currentUser?.givenName || '')
  const [lastName, setLastName] = useState(currentUser?.familyName || '')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [isDirty, setIsDirty] = useState(false)

  const handleUpdatePicture = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleConfirm = () => {
    if (selectedFile) {
      console.log('Confirmed file:', selectedFile)
      setFinalImage(preview)
      setSelectedFile(null)
      setPreview(null)
    }
  }

  const handleCancel = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
    setSelectedFile(null)
    setPreview(null)
  }

  const handleCloseSnackbar = () => setSnackbarOpen(false)

  const handleSubmit = async () => {
    if (!firstName) {
      setFirstNameError(true)
    }
    if (!lastName) {
      setLastNameError(true)
    }

    if (!firstName || !lastName) {
      setSnackbarMessage('First and last name cannot be empty.')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
      return
    }

    try {
      await updateAuthenticUser({ first_name: firstName, last_name: lastName })
      setSnackbarMessage('Profile updated successfully!')
      setSnackbarSeverity('success')
    } catch (err) {
      setSnackbarMessage('Failed to update profile. Please try again.')
      setSnackbarSeverity('error')
    } finally {
      setSnackbarOpen(true)
    }
  }

  useEffect(() => {
    setIsDirty(
      firstName !== currentUser?.givenName || lastName !== currentUser?.familyName
    )
  }, [firstName, lastName, currentUser])

  return (
    <Box sx={{ width: '550px', mb: 3 }}>
      <Box
        sx={{
          position: 'relative',
          width: 'fit-content',
          margin: 'auto',
          '&:hover .edit-button': { opacity: 1 }
        }}
      >
        <Avatar
          sx={{
            width: '140px',
            height: '140px',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': { transform: 'scale(1.1)' }
          }}
          alt='profile-pic'
          src={preview || finalImage || ''}
        />
        <IconButton
          className='edit-button'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: 0,
            transition: 'opacity 0.3s ease-in-out',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.7)' }
          }}
          onClick={handleUpdatePicture}
        >
          <EditIcon />
        </IconButton>
      </Box>

      {preview && (
        <Box mt={2} textAlign='center'>
          <Box display='flex' flexDirection='row' justifyContent='center' gap={2} mt={2}>
            <Button variant='contained' color='primary' onClick={handleConfirm}>
              Confirm
            </Button>
            <Button variant='outlined' color='error' onClick={handleCancel}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}

      <input
        ref={fileInputRef}
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      <Typography variant='h6' gutterBottom>
        Profile Details
      </Typography>
      <Box my={2}>
        <TextField label='Email' value={currentUser.email} disabled fullWidth />
      </Box>
      <Box my={2}>
        <TextField
          label='First Name'
          value={firstName}
          onChange={(e) => {
            setFirstNameError(false)
            setFirstName(e.target.value)
          }}
          fullWidth
          error={firstNameError}
          helperText={firstNameError ? 'First name cannot be empty' : ''}
        />
      </Box>
      <Box my={2}>
        <TextField
          label='Last Name'
          value={lastName}
          onChange={(e) => {
            setLastNameError(false)
            setLastName(e.target.value)
          }}
          fullWidth
          error={lastNameError}
          helperText={lastNameError ? 'Last name cannot be empty' : ''}
        />
      </Box>
      <Button
        variant='contained'
        color='primary'
        onClick={handleSubmit}
        disabled={!isDirty}
      >
        Save
      </Button>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ maxWidth: '400px' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}
