import React, { useState } from 'react'
import {
  Modal,
  Box,
  Slide,
  Typography,
  Snackbar,
  Alert
} from '@mui/material'
import styles from './CreateUserModal.module.css'

export default function CreateUserModal ({ open, onSubmit }) {
  const [name, setName] = useState('')
  const [error, setError] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')

  const handleInputChange = (event) => {
    setName(event.target.value)
    setError('')
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleCreateUser = () => {
    if (!name.trim()) {
      setError('First name is required')
      return
    }

    try {
      onSubmit(name)
      setSnackbarMessage('First name saved successfully')
      setSnackbarSeverity('success')
      setSnackbarOpen(true)
    } catch (error) {
      setSnackbarMessage('Error saving profile')
      setSnackbarSeverity('error')
      setSnackbarOpen(true)
    }
  }

  return (
    <Box>
      <Modal
        open={open}
        onClose={() => {}}
        disableEscapeKeyDown
        closeAfterTransition
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
          }
        }}
      >
        <Slide direction='up' in={open} mountOnEnter unmountOnExit>
          <Box className={styles.modal}>
            <Box className={styles.inputContainer}>
              <Typography variant='subtitle1'>First Name</Typography>
              <input
                type='text'
                value={name}
                onChange={handleInputChange}
                placeholder='Enter your first name'
                className={styles.nameInput}
              />
              {error && (
                <Typography color='error' variant='caption'>
                  {error}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <button
                className={styles.okButton}
                onClick={handleCreateUser}
                data-testid='create-user-button'
              >
                Confirm
              </button>
            </Box>
          </Box>
        </Slide>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ maxWidth: '400px' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}
