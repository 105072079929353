import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton
} from '@mui/material'
import React, { useState } from 'react'
import CircleCheckedFilled from '@mui/icons-material/CheckCircle'
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import CloseIcon from '@mui/icons-material/Close'
import styles from './NewUEButtons.module.css'
import unreal from '../../utils/unreal'
import { usePS } from '../../Context/PSContext'
import config from '../../config'

const modeMapping = {
  move: 0,
  translate: 1,
  rotate: 2,
  scale: 3,
  adjust: 4
}

const generalMenueTools = [
  'adjust',
  'move',
  'pin',
  'point',
  'rotate',
  'scale',
  'selection'
]

const viewMenuTools = ['2D', '3D', 'guest']

const displayLayers = [
  {
    name: 'Deep Utilities',
    alt: 'deepUtil',
    isActive: false
  },
  {
    name: 'Facilities',
    alt: 'facilities',
    isActive: false
  },
  {
    name: 'Land Use',
    alt: 'landUse',
    isActive: false
  },
  {
    name: 'Regulatory and Legal',
    alt: 'legal',
    isActive: false
  },
  {
    name: 'Environmental',
    alt: 'environmental',
    isActive: false
  },
  {
    name: 'Surface Utilities',
    alt: 'surfaceUtil',
    isActive: false
  },
  {
    name: 'Transportation',
    alt: 'transportation',
    isActive: false
  }
]

const iconBasePath = '/images/icons/newUE/'

export default function NewUEButtons ({
  iframeRef,
  isReady,
  multiselect,
  setMultiselect
}) {
  const { PSUrl } = usePS()
  const { UXUpdatesFlag } = config
  const [generalMenu, setGeneralMenu] = useState(false)
  const [viewMenu, setViewMenu] = useState(false)
  const [layerMenu, setLayerMenu] = useState(false)
  const [selectedGeneralMenu, setSelectedGeneralMenu] = useState('adjust')
  const [selectedViewMenu, setSelectedViewMenu] = useState('3D')
  const [layers, setLayers] = useState(displayLayers)

  const toggleGeneralMenu = () => {
    setGeneralMenu((prev) => !prev)
  }

  const toggleViewMenu = () => {
    setViewMenu((prev) => !prev)
  }

  const toggleLayerMenu = () => {
    setLayerMenu((prev) => !prev)
  }

  const handleIconClick = (iconKey) => {
    setSelectedGeneralMenu(iconKey)
    unreal.setObjectMode({
      ref: iframeRef,
      mode: modeMapping[iconKey],
      steps: 10,
      origin: PSUrl
    })
    toggleGeneralMenu()
  }

  const handleViewIconClick = (iconKey) => {
    const newMode = iconKey === '3D' ? 2 : 1
    unreal.switchCameraMode(iframeRef, newMode, PSUrl)
    setSelectedViewMenu(iconKey)
    toggleViewMenu()
  }

  const toggleLayers = (index, event) => {
    const isActive = event.target.checked

    if (layers[index].isActive === isActive) {
      return
    }

    setLayers((prevLayers) => {
      const updatedLayers = [...prevLayers]
      updatedLayers[index].isActive = isActive

      unreal.displayLayer({
        ref: iframeRef,
        layer: index,
        show: isActive ? 1 : 0,
        origin: PSUrl
      })

      return updatedLayers
    })
  }

  const handleCloseLayerMenu = () => {
    setLayerMenu(false)
  }

  const countActiveLayers = () => {
    return layers.filter(layer => layer.isActive).length
  }

  const activeLayerCount = countActiveLayers()

  if (!UXUpdatesFlag || !isReady) return
  return (
    <Box className={styles.ueButtons}>
      <Box
        className={`${styles.ueButtonsContainer} ${generalMenu ? styles.generalMenuOpen : ''}`}
      >
        {!generalMenu && (
          <>
            <Box
              className={`${styles.ueButtonImage} ${selectedGeneralMenu ? styles.selected : ''}`}
              onClick={toggleGeneralMenu}
            >
              <img
                src={`${iconBasePath}${selectedGeneralMenu}.svg`}
                alt='general-menu-icon'
              />
            </Box>
            <Box
              className={styles.ueButtonSmallImage}
              onClick={toggleGeneralMenu}
            >
              <img src='/images/icons/newUE/more.svg' alt='more' />
            </Box>
          </>
        )}
        {generalMenu && (
          <Box className={styles.generalMenu}>
            {generalMenueTools.map((tool) => (
              <Box
                key={tool}
                className={`${styles.menuItem} ${selectedGeneralMenu === tool ? styles.selected : ''}`}
                onClick={() => handleIconClick(tool)}
              >
                <Box className={styles.ueButtonImage}>
                  <img src={`${iconBasePath}${tool}.svg`} alt={tool} />
                </Box>
                <Typography className={styles.menuText}>{tool}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Box
        className={`${styles.ueButtonsContainer} ${viewMenu ? styles.generalMenuOpen : ''}`}
      >
        {!viewMenu && (
          <>
            <Box className={styles.ueButtonImage} onClick={toggleViewMenu}>
              <img
                src={`${iconBasePath}${selectedViewMenu}.svg`}
                alt='selectedViewMenu'
              />
            </Box>
            <Box className={styles.ueButtonSmallImage} onClick={toggleViewMenu}>
              <img src='/images/icons/newUE/more.svg' alt='more' />
            </Box>
          </>
        )}
        {viewMenu && (
          <Box className={styles.generalMenu}>
            {viewMenuTools.map((tool) => (
              <Box
                key={tool}
                className={styles.menuItem}
                onClick={() => handleViewIconClick(tool)}
              >
                <Box className={styles.ueButtonImage}>
                  <img src={`${iconBasePath}${tool}.svg`} alt={tool} />
                </Box>
                <Typography className={styles.menuText}>{tool}</Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Layer Menu */}
      <Box
        className={`${styles.ueButtonsContainer} ${layerMenu ? styles.generalMenuOpen : ''}`}
      >
        {!layerMenu && (
          <>
            <Box
              className={styles.ueButtonImage}
              onClick={toggleLayerMenu}
              sx={{
                backgroundColor: activeLayerCount > 0
                  ? '#FBBE4F'
                  : '#1C1C1D',
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: activeLayerCount > 0
                    ? '#FBBE4F'
                    : '#494B4D'
                }
              }}
            >
              <img
                src={`${iconBasePath}${activeLayerCount > 0
                  ? 'layers-active.svg'
                  : 'layers.svg'}`}
                alt='layers'
              />
            </Box>
            <Box
              className={styles.ueButtonSmallImage}
              onClick={toggleLayerMenu}
            >
              <img src='/images/icons/newUE/more.svg' alt='more' />
            </Box>
          </>
        )}
        {layerMenu && (
          <Box className={styles.generalMenu}>
            {layers.map((layer, index) => (
              <FormControlLabel
                key={layer.name}
                sx={{
                  padding: '5px 0'
                }}
                control={
                  <Checkbox
                    icon={
                      <CircleUnchecked
                        style={{ color: '#494B4D', fontSize: '12px' }}
                      />
                    }
                    checkedIcon={
                      <CircleCheckedFilled
                        style={{ color: '#4A62D8', fontSize: '12px' }}
                      />
                    }
                    checked={layer.isActive}
                    onChange={(event) => {
                      toggleLayers(index, event)
                    }}
                    sx={{
                      padding: '0',
                      '&.Mui-checked': {
                        backgroundColor: 'white'
                      },
                      '& svg': {
                        scale: '1.4'
                      }
                    }}
                  />
                }
                label={
                  <Typography className={styles.menuText}>
                    {layer.name}
                  </Typography>
                }
                className={styles.menuItem}
                style={{ margin: '0', display: 'flex', alignItems: 'center' }}
              />
            ))}
            <Box
              component='div'
              onClick={handleCloseLayerMenu}
              style={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#1C1C1D',
                cursor: 'pointer'
              }}
            >
              <IconButton sx={{ color: 'white' }}>
                <CloseIcon sx={{ fontSize: '12px' }} />
                <Typography
                  fontSize='12px'
                  style={{ marginLeft: '5px' }}
                >
                  Close
                </Typography>
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}
