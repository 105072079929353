import React, { useState, useRef, useEffect } from 'react'
import {
  Box,
  Divider,
  Snackbar,
  Alert,
  CircularProgress,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  IconButton
} from '@mui/material'
import styles from './Toolbar.module.css'
import ProgressBar from '../ProgressBar/ProgressBar'
import ProjectInfo from '../ProjectInfo/ProjectInfo'
import Checklist from '../Checklist/Checklist'
import { useProject } from '../../../Context/ProjectContext'
import { loadWorld, saveWorld } from './utils'
import config from '../../../config'
import { ArrowDropDown, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { usePS } from '../../../Context/PSContext'

const iconButtons = ['cursor', 'zoom', 'pin', 'lasso', 'brush', 'crop', 'trash']
const toolGroups = [
  [
    { type: 'icon', name: 'building', label: 'EXTERIOR', action: () => {} },
    { type: 'icon', name: 'map', label: 'INTELLIGENT MAPS', action: () => {} },
    { type: 'icon', name: 'tools', label: 'INTERIOR', action: () => {} }
  ],
  [
    { type: 'icon', name: 'cube', label: '3D', action: () => {} },
    { type: 'icon', name: 'rectangle', label: 'TOP VIEW', action: () => {} }
  ],
  [
    { type: 'icon', name: 'export-white', label: 'EXPORT', action: () => {} },
    { type: 'icon', name: 'save-white', label: 'SAVE WORLD', action: saveWorld },
    { type: 'icon', name: 'load-white', label: 'LOAD WORLD', action: loadWorld }
  ]
]

export default function Toolbar ({ iframeRef }) {
  const [anchorElInfo, setAnchorElInfo] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorElSave, setAnchorElSave] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarSeverity, setSnackbarSeverity] = useState('success')
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false)
  const { project, setProject } = useProject()
  const { saveWorldFlag } = config
  const [expandedGroup, setExpandedGroup] = useState(saveWorldFlag ? 2 : 1)
  const autoSaveIntervalRef = useRef(null)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1200)
  const { PSUrl } = usePS()

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1200)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    return () => {
      if (autoSaveIntervalRef.current) {
        clearInterval(autoSaveIntervalRef.current)
      }
    }
  }, [])

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(severity)
    setSnackbarOpen(true)
  }

  const handleToolActions = (icon) => {
    if (icon.label === 'SAVE WORLD') {
      icon.action(project, handleSnackbar, setSaving)
    } else if (icon.label === 'LOAD WORLD') {
      icon.action({
        iframeRef,
        project,
        setProject,
        PSUrl,
        handleSnackbar,
        setLoading
      })
    } else {
      icon.action()
    }
  }

  const handleAutoSaveChange = (event) => {
    const isChecked = event.target.checked
    setAutoSaveEnabled(isChecked)

    if (isChecked) {
      autoSaveIntervalRef.current = setInterval(() => {
        setAnchorElSave(null)
        saveWorld(project, handleSnackbar, setSaving)
      }, 60000)
    } else {
      if (autoSaveIntervalRef.current) {
        clearInterval(autoSaveIntervalRef.current)
      }
    }
  }

  const handleDropdownClick = (event) => {
    setAnchorElSave(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorElSave(null)
  }

  const updatedMainToolIcons = saveWorldFlag
    ? toolGroups
    : toolGroups.slice(0, -1)

  const handleGroupToggle = (index) => {
    if (expandedGroup === index) {
      setExpandedGroup(null)
    } else {
      setExpandedGroup(index)
    }
  }
  return (
    <Box className={styles.toolbarContainer}>
      <Box className={styles.projectInfo}>
        <Box className={styles.nameContainer}>
          <p>{project?.project_name}</p>
          <p onClick={(e) => setAnchorElInfo(e.currentTarget)}>Project Info</p>
          <ProjectInfo
            project={project}
            anchorElInfo={anchorElInfo}
            setAnchorElInfo={setAnchorElInfo}
          />
        </Box>
        <Box className={styles.targets}>
          <p>Current Budget Estimate</p>
          <p>Target Investment {project?.target_investment}$</p>
          <ProgressBar
            totalSteps={7}
            currentStep={2}
            setAnchorEl={setAnchorEl}
          />
        </Box>
        <Box className={styles.todoChecklist}>
          <p>Confidence Score %</p>
          <p onClick={(e) => setAnchorEl(e.currentTarget)}>To-Do Checklist</p>
          <Checklist anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </Box>
      </Box>
      <Box className={styles.tools}>
        <Box className={styles.mainTools}>
          {updatedMainToolIcons.map((group, index) => (
            <React.Fragment key={index}>
              {group.map((icon) => (
                (expandedGroup === index || !isSmallScreen)
                  ? (
                    <Box
                      component='div'
                      key={icon.name}
                      className={styles.mainIconContainer}
                    >
                      {icon.label === 'SAVE WORLD' && saving
                        ? (
                          <CircularProgress size={21} />
                          )
                        : icon.label === 'LOAD WORLD' && loading
                          ? (
                            <CircularProgress size={21} />
                            )
                          : icon.label === 'SAVE WORLD'
                            ? (
                              <Box>
                                <img
                                  src={`/images/icons/${icon.name}.svg`}
                                  alt={icon.name}
                                  className={styles.mainToolsIcon}
                                  onClick={() => handleToolActions(icon)}
                                />
                                <Box
                                  component='div'
                                  display='inline-block'
                                  onClick={handleDropdownClick}
                                >
                                  <ArrowDropDown sx={{ color: 'white' }} />
                                </Box>
                              </Box>
                              )
                            : (
                              <img
                                src={`/images/icons/${icon.name}.svg`}
                                alt={icon.name}
                                className={styles.mainToolsIcon}
                                onClick={() => handleToolActions(icon)}
                              />
                              )}
                      <p className={styles.iconLabel}>{icon.label}</p>
                    </Box>
                    )
                  : null
              ))}
              {expandedGroup !== index && isSmallScreen && (
                <IconButton
                  padding={0}
                  onClick={() => {
                    handleGroupToggle(index)
                  }}
                >
                  {expandedGroup > index
                    ? <ChevronLeft
                        sx={{ color: '#DCE2E5' }}
                      />
                    : <ChevronRight
                        sx={{ color: '#DCE2E5' }}
                      />}
                </IconButton>
              )}
              {index !== updatedMainToolIcons.length - 1 && (
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ backgroundColor: '#DCE2E5', height: '40px' }}
                />
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box className={styles.otherTools}>
          {iconButtons.map((icon) => (
            <Box
              key={icon}
              className={styles.iconContainer}
            >
              <img
                src={`/images/icons/${icon}.svg`}
                alt={icon}
                className={styles.otherToolsIcon}
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Menu
        anchorEl={anchorElSave}
        open={Boolean(anchorElSave)}
        onClose={handleDropdownClose}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={autoSaveEnabled}
                onChange={handleAutoSaveChange}
              />
            }
            label='Auto-Save every 1 minute'
          />
        </MenuItem>
      </Menu>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ maxWidth: '400px' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}
