import React, { createContext, useContext, useEffect, useState, useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { findUser, createUserProfile, updateUserProfile } from '../api'
import Loading from '../components/Loading/Loading'
import CreateUserModal from '../components/Landing/CreateUserModal/CreateUserModal'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const tokenKey = '_aui_authToken'
  const navigate = useNavigate()

  const fetchUser = useCallback(async () => {
    const token = localStorage.getItem(tokenKey)
    try {
      const user = await findUser()
      setCurrentUser({
        email: user.email,
        givenName: user.first_name,
        familyName: user.last_name
      })
    } catch (err) {
      if (err.response?.status === 404) {
        const [, payload] = token.split('.')
        const { email } = JSON.parse(atob(payload))

        setCurrentUser({ email, givenName: '' })
        setShowCreateUserModal(true)
      }
      if (err.response?.status === 401 || err.response?.status === 403) {
        localStorage.removeItem(tokenKey)
        setError('Unauthorized')
        navigate('/login')
      }
    } finally {
      setLoading(false)
    }
  }, [navigate, tokenKey])

  const createAuthenticUser = useCallback(async (firstName) => {
    const user = await createUserProfile(firstName)
    setCurrentUser({
      email: user.email,
      givenName: user.first_name,
      familyName: user.last_name
    })
    setShowCreateUserModal(false)
  }, [])

  const updateAuthenticUser = useCallback(async (updatedDetails) => {
    const updatedUser = await updateUserProfile(updatedDetails)
    setCurrentUser({
      email: updatedUser.email,
      givenName: updatedUser.first_name,
      familyName: updatedUser.last_name
    })
    return updatedUser
  }, [])

  useEffect(() => {
    const token = searchParams.get('jwt') || localStorage.getItem(tokenKey)
    if (!token) return setLoading(false)
    localStorage.setItem(tokenKey, token)

    searchParams.delete('jwt')
    setSearchParams(searchParams)

    if (!error) fetchUser(token)
  }, [searchParams, setSearchParams, error, fetchUser])

  const logoutAuthentic = async () => {
    localStorage.removeItem(tokenKey)
    setCurrentUser(null)
    navigate('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        createAuthenticUser,
        updateAuthenticUser,
        logoutAuthentic,
        error
      }}
    >
      {(loading && !currentUser)
        ? <Loading />
        : (
          <>
            {children}
            <CreateUserModal
              open={showCreateUserModal}
              onSubmit={(name) => createAuthenticUser(name)}
            />
          </>
          )}
    </AuthContext.Provider>
  )
}
