import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import styles from './styles.module.css'
import {
  Add, ArrowDropDown, ArrowDropUp, Autorenew, Circle
} from '@mui/icons-material'

const processPhases = Array.from({ length: 7 }, (_, i) => ({
  name: (i + 1).toString(),
  active: i < 3
}))

const menuLists = {
  onStage: 'On-stage',
  backstage: 'Backstage',
  infrastructure: 'Infrastructure'
}

const listsItems = {
  [menuLists.onStage]: ['Attraction facade', 'Plaza', 'Queue', 'Restrooms', 'Stroller Area'],
  [menuLists.backstage]: ['Attraction Facility', 'Attraction Support Facility', 'Operations Bay'],
  [menuLists.infrastructure]: ['Electrical', 'Gas', 'Plumbing']
}

const listColors = {
  [menuLists.onStage]: '#4A62D8',
  [menuLists.backstage]: '#9747FF',
  [menuLists.infrastructure]: '#1AB28A'
}

function SideMenu ({ openLists, setOpenLists }) {
  const isListActive = listName => openLists?.includes(listName)

  const handleListClick = (listName) => {
    const newOpenLists = openLists.includes(listName)
      ? openLists.filter(list => list !== listName)
      : [...openLists, listName]
    setOpenLists(newOpenLists)
  }

  return (
    <Box className={styles.sideMenuContainer}>
      <Box className={styles.sideMenuItemsContainer}>
        <Box className={styles.phaseContainer}>
          <Box className={styles.phaseTextWrapper}>
            <p>process phase</p>
            <Typography variant='h4'>Pre-Blue Sky</Typography>
          </Box>

          <Box className={styles.phaseProgress}>
            {processPhases.map(({ name, active }) => (
              <span key={name} className={active ? styles.activePhase : ''} />
            ))}
          </Box>
        </Box>

        {Object.values(menuLists).map(listName => (
          <Box key={listName} className={styles.listContainer}>
            <Box
              className={styles.listHeadline}
              sx={{ color: listColors[listName] }}
              onClick={() => handleListClick(listName)}
            >
              <Typography variant='h4'>{listName}</Typography>
              {isListActive(listName)
                ? <ArrowDropUp />
                : <ArrowDropDown />}
            </Box>

            {isListActive(listName) && listsItems[listName].map(item => {
              const isInactive = item === 'Stroller Area'
              return (
                <Box
                  key={item}
                  className={styles.listItem}
                  sx={{
                    color: isInactive ? '#939699' : '#F5F8FA'
                  }}
                >
                  <Typography>{item}</Typography>
                  <Box><Circle sx={{ width: '8px' }} /></Box>
                </Box>
              )
            })}

            <Box
              className={styles.listItem}
              sx={{
                padding: '6px 11px 6px 8px',
                background: 'transparent'
              }}
            >
              <Typography>Add {listName.toLowerCase()} asset</Typography>
              <Add sx={{ width: '18px', height: '18px' }} />
            </Box>
          </Box>
        ))}
      </Box>

      <Button
        className={styles.regenerateButton}
      >
        <Autorenew sx={{ width: '16px', height: '16px' }} />
        <Typography>Regenerate rational layout</Typography>
      </Button>
    </Box>
  )
}

export default SideMenu
