import React, { useEffect, useState } from 'react'
import { Link, useMatch, useNavigate } from 'react-router-dom'
import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { useAuth } from '../../Context/AuthContext'
import styles from './NavBar.module.css'
import { ArrowDropDown } from '@mui/icons-material'
import BudgetDrawer from '../BudgetDrawer/BudgetDrawer'
import ChatModal from '../ChatModal/ChatModal'
import { generateTextContent } from '../../utils/projects'
import {
  checkMessageStatus,
  fetchWithLoading,
  retrieveMessages,
  sendMessage,
  updateProject
} from '../../api'
import { MESSAGE_STATUS } from '../../constants/messages'
import { useProject } from '../../Context/ProjectContext'
import config from '../../config'

const { userMenuFlag } = config

export default function NavBar ({ isMapReady, setOpenSideMenu = () => {} }) {
  const { currentUser, logoutAuthentic } = useAuth()
  const { project, threadId, setThreadId } = useProject()
  const matchMap = useMatch('/map/:id')
  const navigate = useNavigate()
  const [activeItem, setActiveItem] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [conversations, setConversations] = useState([])
  const [followups, setFollowups] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  async function submitValue (newPrompt, persona) {
    setConversations(prev => [...prev, {
      role: 'user',
      content: generateTextContent(newPrompt)
    }, {
      role: '',
      content: generateTextContent('Loading...')
    }])

    try {
      setLoading(true)
      const messageResponse = await sendMessage(
        {
          message: newPrompt,
          layout: project?.world_data,
          ...(threadId && { thread_id: threadId })
        }
      )

      const newThreadId = messageResponse?.thread_id

      if (!threadId) {
        setThreadId(newThreadId)
        await updateProject(project?._id, { thread_id: newThreadId })
      }

      const response = await checkStatus(newThreadId)

      if (response.status === MESSAGE_STATUS.completed) {
        const projectData = await fetchWithLoading(
          () => retrieveMessages({ threadId: threadId || newThreadId }),
          setLoading,
          setError
        )
        setFollowups(projectData.data[0].content[0].text.followups)
        setConversations(projectData.data.reverse())
      } else if (response.status === MESSAGE_STATUS.failed) {
        const errorMessage = 'Something went wrong. Please retry'
        displayErrorMessage(errorMessage)
        setLoading(false)
        setError(errorMessage)
      }
    } catch (error) {
      console.error('API error:', error)
      displayErrorMessage(error.message)
      setError(error.message)
      setLoading(false)
    }
  }

  const displayErrorMessage = (errorMessage) => {
    setConversations(prevConversations => {
      if (prevConversations.length === 0) return prevConversations
      const newConversations = [...prevConversations]
      const lastElementIndex = newConversations.length - 1
      newConversations[newConversations.length - 1] = {
        ...newConversations[lastElementIndex],
        type: 'error',
        content: generateTextContent(errorMessage)
      }
      return newConversations
    })
  }

  const checkStatus = async (threadId) => {
    try {
      const statusResponse = await checkMessageStatus({ threadId })
      const status = statusResponse.status
      if ([MESSAGE_STATUS.completed, MESSAGE_STATUS.failed].includes(status)) {
        return statusResponse
      } else {
        await new Promise(resolve => setTimeout(resolve, 1000))
        return checkStatus(threadId)
      }
    } catch (error) {
      console.error('Failed to check message status:', error)
      throw error
    }
  }

  const handleActiveItem = (item) => {
    setActiveItem(prevItem => (prevItem === item ? '' : item))
  }

  const handleOpenUserMenu = (event) => {
    if (!userMenuFlag) return
    setAnchorEl(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    logoutAuthentic()
  }

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const res = await fetchWithLoading(
          () => retrieveMessages({ threadId }),
          setLoading,
          setError
        )
        setFollowups(res.data[0]?.content[0]?.text?.followups)
        setConversations(res.data.reverse())
      } catch (err) {
        navigate('/')
      }
    }
    if (threadId) fetchProject()
    else {
      setConversations([])
    }
  }, [threadId, navigate])

  const isActive = (item) => item === activeItem

  useEffect(() => {
    setOpenSideMenu(activeItem === 'layout')
  }, [activeItem, setOpenSideMenu])

  if (!currentUser) return null

  return (
    <>
      <Box
        className={styles.navBarContainer}
        sx={{
          backgroundColor: matchMap ? '#131415' : 'transparent !important',
          borderBottom: matchMap ? '1px solid #1C1C1D' : 'none'
        }}
      >
        <Box
          className={styles.profileContainer}
        >
          <Typography
            color={matchMap ? '#FFF' : '#000'}
            fontSize='12px'
            fontWeight={500}
          >
            {currentUser.givenName}
          </Typography>
          <IconButton
            onClick={handleOpenUserMenu}
          >
            <img
              src={currentUser.picture ||
                `/images/icons/profile-icon${matchMap ? '-white' : ''}.svg`}
              alt='profile'
              className={styles.profileIcon}
              data-testid='profile'
            />
          </IconButton>
          <Menu
            sx={{ mt: '35px' }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem
              onClick={() => {
                navigate('/settings')
              }}
            >
              <Typography textAlign='center'>Settings</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <Typography textAlign='center'>Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
        {matchMap && (
          <>
            <Box
              flexGrow={1}
              display='flex'
              justifyContent='center'
            >
              <Box
                component='div'
                className={styles.inputContainer}
                onClick={() => setIsModalOpen(true)}
              >
                <input
                  className={styles.customInput}
                  type='text'
                  placeholder='Search or talk to AI Agent'
                />
                <Box className={styles.adornment}>
                  <img
                    src='/images/icons/mic-white.svg'
                    alt='icon'
                    className={styles.adornmentIcon}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: isActive('estimate')
                  ? '3px solid #fff'
                  : '3px solid transparent'
              }}
            >
              <Typography
                component='p'
                className={styles.navBarItem}
                onClick={() => handleActiveItem('estimate')}
              >
                estimate
              </Typography>
            </Box>
            {isMapReady && (
              <Box
                sx={{
                  borderBottom: isActive('layout')
                    ? '3px solid #fff'
                    : '3px solid transparent'
                }}
              >
                <Typography
                  component='p'
                  className={styles.navBarItem}
                  onClick={() => handleActiveItem('layout')}
                >
                  layout
                </Typography>
              </Box>
            )}
            <Box className={styles.navBarContent}>
              <Link
                to='/'
                style={{
                  display: 'inline-block',
                  width: '24px',
                  height: '24px'
                }}
              >
                <img
                  src='/images/ai-inkwell-mini-logo-white.svg'
                  alt='logo'
                  className={styles.aiInkwellLogo}
                />
              </Link>
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
              >
                <Typography
                  fontWeight={600}
                  fontSize='20px'
                  sx={{
                    userSelect: 'none'
                  }}
                >
                  PETAL
                </Typography>
                <ArrowDropDown
                  sx={{
                    color: '#939699'
                  }}
                />
              </Box>
            </Box>
          </>
        )}

      </Box>
      <ChatModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentView='chat'
        conversations={conversations}
        loading={loading}
        error={error}
        submitValue={submitValue}
        followups={followups}
        setConversations={setConversations}
      />
      <BudgetDrawer
        open={isActive('estimate')}
        setOpen={(open) => setActiveItem(open ? 'estimate' : '')}
      />
    </>
  )
}
