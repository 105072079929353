import { createContext, useContext, useState, useEffect } from 'react'
import { DEVELOPER_PS_URL, PROD_PS_URL, STAGING_PS_URL } from '../api'

const PSContext = createContext()

export const usePS = () => useContext(PSContext)

export const PSProvider = ({ children, value = {} }) => {
  const [PSUrl, setPSUrl] = useState(value.PSUrl || null)

  useEffect(() => {
    if (!value.PSUrl) {
      if (process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_PS_URL) {
        setPSUrl(process.env.REACT_APP_PS_URL)
      } else {
        const currentUrl = window.location.href
        if (currentUrl.includes('https://ecco.wdi.aiinkwell.app')) {
          setPSUrl(PROD_PS_URL)
        } else if (currentUrl
          .includes('https://staging.ecco.wdi.aiinkwell.app')) {
          setPSUrl(STAGING_PS_URL)
        } else {
          setPSUrl(DEVELOPER_PS_URL)
        }
      }
    }
  }, [value.PSUrl])

  return (
    <PSContext.Provider value={{ PSUrl }}>
      {children}
    </PSContext.Provider>
  )
}
