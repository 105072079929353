import { Box, Popover, Typography } from '@mui/material'
import React, { useState } from 'react'

import styles from './UEButtons.module.css'
import unreal from '../../utils/unreal'
import config from '../../config'
import { usePS } from '../../Context/PSContext'

const modeMapping = {
  dragDrop: 0,
  translate: 1,
  rotate: 2,
  scale: 3,
  pointEdit: 4
}

const displayLayers = [
  {
    name: 'Deep Utilities',
    alt: 'deepUtil',
    isActive: false
  },
  {
    name: 'Facilities',
    alt: 'facilities',
    isActive: false
  },
  {
    name: 'Land Use',
    alt: 'landUse',
    isActive: false
  },
  {
    name: 'Regulatory and Legal',
    alt: 'legal',
    isActive: false
  },
  {
    name: 'Environmental',
    alt: 'environmental',
    isActive: false
  },
  {
    name: 'Surface Utilities',
    alt: 'surfaceUtil',
    isActive: false
  },
  {
    name: 'Transportation',
    alt: 'transportation',
    isActive: false
  }
]
export default function UEButtons ({
  iframeRef,
  isReady,
  multiselect,
  setMultiselect
}) {
  const { PSUrl } = usePS()
  const { ueUiFlag } = config
  const [cameraMode, setCameraMode] = useState(2)
  const [activeMode, setActiveMode] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverText, setPopoverText] = useState('')
  const [layersExpanded, setLayersExpanded] = useState(false)
  const [layers, setLayers] = useState(displayLayers)
  const [isTapeActive, setIsTapeActive] = useState(false)

  const handlePopoverOpen = (event, text) => {
    setPopoverText(text)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleModeClick = (mode) => {
    setActiveMode(mode)
    unreal.setObjectMode({
      ref: iframeRef,
      mode: modeMapping[mode],
      steps: 10,
      origin: PSUrl
    })
  }

  const handleTapeClick = () => {
    setIsTapeActive(prev => !prev)
    unreal.tapeMeasure({
      ref: iframeRef,
      state: isTapeActive ? 0 : 1,
      origin: PSUrl
    })
  }

  const handleMultiselect = (mode) => {
    const multiSelectMode = multiselect === mode ? 0 : mode
    unreal.multiSelect({
      ref: iframeRef,
      mode: multiSelectMode,
      origin: PSUrl
    })
    setMultiselect(multiSelectMode)
  }

  const handleCameraModeSwitch = () => {
    const newMode = cameraMode === 1 ? 2 : 1
    setCameraMode(newMode)
    unreal.switchCameraMode(iframeRef, newMode, PSUrl)
  }

  const isActive = (mode) => {
    return mode === activeMode ? styles.active : ''
  }

  const toggleLayerIcons = () => {
    setLayersExpanded((prev) => !prev)
  }

  const toggleLayers = (index) => {
    unreal.displayLayer({
      ref: iframeRef,
      layer: index,
      show: layers[index].isActive ? 0 : 1,
      origin: PSUrl
    })
    setLayers((prevLayers) => {
      const updatedLayers = [...prevLayers]
      updatedLayers[index].isActive = !updatedLayers[index].isActive
      return updatedLayers
    })
  }

  if (!ueUiFlag || !isReady) return null

  return (
    <Box>
      <Box className={`${styles.ueButtons} ${styles.horizontal}`}>
        <Box
          className={styles.ueButtonIcon}
          borderRadius='30px'
          onClick={handleCameraModeSwitch}
          onMouseEnter={(e) => { handlePopoverOpen(e, 'Camera Mode') }}
          onMouseLeave={handlePopoverClose}
        >
          <Typography fontWeight={700}>
            {cameraMode === 2 ? '3D' : '2D'}
          </Typography>
        </Box>
        <Box
          display='flex'
          gap={0}
        >
          <Box
            className={`${styles.ueButtonIcon} ${isActive('dragDrop')}`}
            borderRight='1px solid grey'
            borderRadius='30px 0 0 30px'
            onClick={() => handleModeClick('dragDrop')}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Drag & Drop') }}
            onMouseLeave={handlePopoverClose}
          >
            <img
              src='/images/icons/UE/drag-drop.svg'
              width='24px'
              alt='drag&drop'
            />
          </Box>
          <Box
            className={`${styles.ueButtonIcon} ${isActive('translate')}`}
            borderLeft='1px solid grey'
            borderRight='1px solid grey'
            onClick={() => handleModeClick('translate')}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Translate Object') }}
            onMouseLeave={handlePopoverClose}
          >
            <img src='/images/icons/UE/move.svg' width='24px' alt='move' />
          </Box>
          <Box
            className={`${styles.ueButtonIcon} ${isActive('rotate')}`}
            borderLeft='1px solid grey'
            borderRight='1px solid grey'
            onClick={() => handleModeClick('rotate')}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Rotate Object') }}
            onMouseLeave={handlePopoverClose}
          >
            <img src='/images/icons/UE/rotate.svg' width='24px' alt='rotate' />
          </Box>
          <Box
            className={`${styles.ueButtonIcon} ${isActive('scale')}`}
            borderLeft='1px solid grey'
            borderRight='1px solid grey'
            onClick={() => handleModeClick('scale')}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Scale Object') }}
            onMouseLeave={handlePopoverClose}
          >
            <img src='/images/icons/UE/scale.svg' width='24px' alt='scale' />
          </Box>
          <Box
            className={`${styles.ueButtonIcon} ${isActive('pointEdit')}`}
            borderRadius='0 30px 30px 0'
            borderLeft='1px solid grey'
            onClick={() => handleModeClick('pointEdit')}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Point Editing') }}
            onMouseLeave={handlePopoverClose}
          >
            <img
              src='/images/icons/UE/points-edit.svg'
              width='24px'
              alt='point-edit'
            />
          </Box>
        </Box>
      </Box>
      <Box className={`${styles.ueButtons} ${styles.vertical}`}>
        <Box
          display='flex'
          flexDirection='column'
          gap={1}
          sx={{
            backgroundColor: '#494B4D',
            borderRadius: '30px',
            paddingTop: layersExpanded ? '12px' : 0
          }}
        >
          {layersExpanded && layers.map((layer, index) => (
            <Box
              key={index}
              className={styles.layerIcon}
              onMouseEnter={(e) => handlePopoverOpen(e, layer.name)}
              onMouseLeave={handlePopoverClose}
              onClick={() => toggleLayers(index)}
            >
              <img
                src={`/images/icons/UE/${layer.alt}${layer.isActive ? 'Selected' : ''}.svg`}
                width='24px' alt='layer.alt'
              />
            </Box>
          ))}
          <Box
            className={`${styles.ueButtonIcon} ${layersExpanded ? styles.active : ''}`}
            borderRadius='30px'
            onClick={toggleLayerIcons}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Layers') }}
            onMouseLeave={handlePopoverClose}
          >
            <img src='/images/icons/UE/layers.svg' width='24px' alt='layers' />
          </Box>
        </Box>
        <Box
          className={`${styles.ueButtonIcon} ${isTapeActive && styles.active}`}
          borderRadius='30px'
          onClick={handleTapeClick}
          onMouseEnter={(e) => { handlePopoverOpen(e, 'Tape Measure') }}
          onMouseLeave={handlePopoverClose}
        >
          <img
            src='/images/icons/UE/measuring-tape.svg'
            width='24px'
            alt='measuring-tape'
          />
        </Box>
        <Box>
          <Box
            className={`${styles.ueButtonIcon} ${multiselect === 2 && styles.active}`}
            onClick={() => handleMultiselect(2)}
            sx={{
              borderRadius: '30px 30px 0 0',
              borderBottom: '1px solid grey'
            }}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Multiselect - Lasso') }}
            onMouseLeave={handlePopoverClose}
          >
            <img
              src='/images/icons/UE/lasso.svg'
              width='24px'
              alt='lasso-select'
            />
          </Box>
          <Box
            className={`${styles.ueButtonIcon} ${multiselect === 1 && styles.active}`}
            onClick={() => handleMultiselect(1)}
            sx={{
              borderRadius: '0 0 30px 30px',
              borderTop: '1px solid grey'
            }}
            onMouseEnter={(e) => { handlePopoverOpen(e, 'Multiselect - Rectangle') }}
            onMouseLeave={handlePopoverClose}
          >
            <img
              src='/images/icons/UE/rectangle.svg'
              width='24px'
              alt='rectangle-select'
            />
          </Box>
        </Box>
      </Box>
      <Popover
        id='mouse-over-popover'
        sx={{ pointerEvents: 'none' }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 0.5 }} fontSize={12}>{popoverText}</Typography>
      </Popover>
    </Box>
  )
}
