import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Toolbar from '../../components/Map/Toolbar/Toolbar'
import Description from '../../components/Map/Description/Description'
import Loading from '../../components/Loading/Loading'
import FloatingButtons from '../../components/FloatingButtons/FloatingButtons'
import { getProject, loadProjectJson } from '../../api'
import unreal from '../../utils/unreal'
import { useProject } from '../../Context/ProjectContext'
import styles from './Map.module.css'
import UEButtons from '../../components/UEButtons/UEButtons'
import { usePS } from '../../Context/PSContext'
import NavBar from '../../components/NavBar/NavBar'
import config from '../../config'
import SideMenu from '../../components/SideMenu'
import NewUEButtons from '../../components/UEButtons/NewUEButtons'

export default function Map () {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const {
    project,
    setProject,
    setBudget,
    threadId,
    setThreadId,
    updateWorldState
  } = useProject()

  const { PSUrl } = usePS()
  const [loading, setLoading] = useState(false)
  const [openDescription, setOpenDescription] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [multiselect, setMultiselect] = useState(0)
  const { UXUpdatesFlag } = config
  const iframeRef = useRef()

  const [openSideMenu, setOpenSideMenu] = useState(false)
  const [openLists, setOpenLists] = useState([])

  useEffect(() => {
    if (project) {
      const cleaner = unreal.handleMessages({
        ref: iframeRef,
        project,
        setBudget,
        updateProjectState: updateWorldState,
        threadId,
        setThreadId,
        setIsReady,
        setMultiselect
      }
      )
      return cleaner
    }

    // eslint-disable-next-line
  }, [project, updateWorldState])

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true)
      try {
        const response = await getProject(projectId)
        if (!response.world_data) {
          // mock world data
          const res = await loadProjectJson()
          response.world_data = { ...res, name: response.project_name }
        }
        if (response) {
          setProject(response)
          setThreadId(response?.thread_id)
        } else navigate('/')
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
    fetchProject()
    // eslint-disable-next-line
  }, [navigate, projectId, setProject])

  if (loading) return <Loading />
  return (
    <Box className={styles.mapContainer}>
      {UXUpdatesFlag
        ? <NavBar isMapReady={isReady} setOpenSideMenu={setOpenSideMenu} />
        : <Toolbar iframeRef={iframeRef} />}
      <Box
        className={styles.body}
        sx={{
          height: 'calc(100vh - 72px)'
        }}
      >
        <Box>
          {UXUpdatesFlag
            ? <NewUEButtons
                iframeRef={iframeRef}
                isReady={isReady}
                multiselect={multiselect}
                setMultiselect={setMultiselect}
              />
            : <UEButtons
                iframeRef={iframeRef}
                isReady={isReady}
                multiselect={multiselect}
                setMultiselect={setMultiselect}
              />}
          {!UXUpdatesFlag && <FloatingButtons />}
          {UXUpdatesFlag && openSideMenu && (
            <SideMenu
              openLists={openLists}
              setOpenLists={setOpenLists}
            />
          )}
          <iframe
            ref={iframeRef}
            src={PSUrl}
            className={styles.iframe}
            title='map'
            data-testid='iframe'
          />
        </Box>
        <Description open={openDescription} setOpen={setOpenDescription} />
      </Box>
    </Box>
  )
}
