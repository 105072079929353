import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import UserDetails from '../../components/Settings/UserDetails'
import NavBar from '../../components/NavBar/NavBar'
import config from '../../config'

export default function Settings () {
  const { userMenuFlag } = config
  return (
    <Box
      sx={{
        boxSizing: 'border-box'
      }}
    >
      {userMenuFlag && <NavBar />}
      <Container sx={{ mt: 3 }}>
        <Typography variant='h5'>
          Settings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <UserDetails />
        </Box>
      </Container>
    </Box>
  )
}
