import { Routes, Route } from 'react-router-dom'
import { AuthProvider } from './Context/AuthContext'
import { ThemeProvider } from '@mui/material'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import Login from './pages/Login/Login'
import Home from './pages/Home/Home'
import Settings from './pages/Settings/Settings'
import ComingSoon from './pages/ComingSoon/ComingSoon'
import { theme } from './theme'
import NotFound from './pages/NotFound/NotFound'
import Map from './pages/Map'
import PSPlayer from './pages/PSPlayer'
import config from './config'
import { ProjectProvider } from './Context/ProjectContext'
import { PSProvider } from './Context/PSContext'

const { userMenuFlag } = config

function App () {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <ProjectProvider>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route
              path='/'
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
            }
            />
            <Route
              path='/map/:projectId'
              element={
                <ProtectedRoute>
                  <PSProvider>
                    <Map />
                  </PSProvider>
                </ProtectedRoute>
            }
            />
            {userMenuFlag && (
              <Route
                path='/settings'
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
              }
              />
            )}
            <Route
              path='/player'
              element={
                <ProtectedRoute>
                  {config.mockPSFlag ? <PSPlayer /> : <NotFound />}
                </ProtectedRoute>
            }
            />
            <Route path='/coming-soon' element={<ComingSoon />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </ProjectProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
