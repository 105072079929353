import {
  getBudget,
  getParkData,
  getResortData,
  getWorldData,
  sendMessage as sendEstimatorMessage,
  updateProject
} from '../api.js'

const DEFAULT_ORIGIN = window.location.origin
const TOKEN_KEY = '_aui_authToken'

const resortIds = [
  '4795362',
  '205732748',
  '377094298'
]

const parkIds = [
  '5586855',
  '15626312'
]

const LOADED_MAP_KEY = (projectId) => `${projectId}_loaded-map`
const setLoadedMap = (projectId, value) => localStorage.setItem(LOADED_MAP_KEY(projectId), value)
const getLastLoadedMap = (projectId) => localStorage.getItem(LOADED_MAP_KEY(projectId))

function init (event, ref, project) {
  const { origin } = event || {}
  const message = {
    messageType: 'PS',
    messageId: 'SetToken',
    data: localStorage.getItem(TOKEN_KEY) || 'test-token'
  }

  postMessage(ref, message, origin)
}

async function handlePSMessages ({
  event, ref, project, threadId, setThreadId, setIsReady
}) {
  if (event.data.messageType === 'PS' &&
  event.data.messageId === 'MessageFromUE') return

  const { data: eventType } = event.data

  switch (eventType) {
    case 'WaitingForToken':
      return init(event, ref, project)

    case 'Presenting': {
      const isInitialLayoutSent = localStorage.getItem(`initial-layout_${project?._id}`)
      if (!isInitialLayoutSent) {
        updateEstimator(event, project, threadId, setThreadId)
        localStorage.setItem(`initial-layout_${project?._id}`, true)
      }
      break
    }

    case 'Ready': {
      setIsReady(true)

      setTimeout(async () => {
        switchCameraMode(ref, 2, event.origin)
      }, 1000)

      const lastLoadedMap = getLastLoadedMap(project?._id)
      if (lastLoadedMap) return

      setTimeout(async () => {
        const worldData = await getWorldData()
        load(ref, event.origin, worldData)
        setLoadedMap(project?._id, 'world')
        switchCameraMode(ref, 2, event.origin)
      }, 1000)

      break
    }

    default:
      break
  }
}

async function handleUEMessages ({
  event,
  ref,
  project,
  setBudget,
  updateProjectState,
  threadId,
  setThreadId,
  setMultiselect
}) {
  const { action, data } = event.data?.data || {}
  if (event.data?.messageType === 'PS' &&
  event.data.messageId !== 'MessageFromUE') return

  if (action === 'update') {
    const newWorldState = updateParkData(project.world_data, data)
    updateProjectState(newWorldState)
    const updatedProject = { ...project, world_data: newWorldState }
    updateEstimator(event, updatedProject, threadId, setThreadId)
    const budget = await getBudget()
    setBudget(budget)
  }

  if (action === 'select') {
    setMultiselect(0)
  }

  if (action === 'load_world') {
    const shouldLoadWorld = data?.id === 'World'
    if (shouldLoadWorld) {
      const worldData = await getWorldData()
      load(ref, event.origin, worldData)
      setLoadedMap(project?._id, 'world')
      return
    }

    const shouldLoadResort = resortIds.includes(data?.id)
    if (shouldLoadResort) {
      const parkData = await getResortData()
      load(ref, event.origin, parkData)
      setLoadedMap(project?._id, 'resort')
      return
    }

    const shouldLoadPark = parkIds.includes(data?.id)
    if (shouldLoadPark) {
      const parkData = await getParkData()
      load(ref, event.origin, parkData)
      setLoadedMap(project?._id, 'park')
    }
  }
}

function handleMessages ({
  ref,
  project,
  setBudget,
  updateProjectState,
  threadId,
  setThreadId,
  setIsReady,
  setMultiselect
}) {
  const messagesHandler = (event) => {
    if (event?.data?.source?.includes('react-devtools')) return

    handlePSMessages({ event, ref, project, threadId, setThreadId, setIsReady })
    handleUEMessages({
      event,
      ref,
      project,
      setBudget,
      updateProjectState,
      threadId,
      setThreadId,
      setMultiselect
    })
  }
  window.addEventListener('message', messagesHandler)

  return () => window.removeEventListener('message', messagesHandler)
}

// TO-DO
// 1. move updating the estimator outside of the unreal module
// 2. only update the estimator on commands after presenting and only on production iframe

async function updateEstimator (event, project, threadId, setThreadId) {
  const excludedEvents = [
    'SelectPresentation',
    'Authenticating',
    'WaitingForToken'
  ]
  const isExcluded = excludedEvents.includes(event?.data?.data)
  if (!project || isExcluded) return

  const data = {
    message: !threadId ? 'Sending Initial layout...' : 'Changes made in the layout…',
    layout: project,
    ...(threadId && { thread_id: threadId })
  }

  try {
    const { thread_id: newThreadId } = await sendEstimatorMessage(data) || {}

    if (!threadId) {
      setThreadId(newThreadId)
      await updateProject(project?._id, { thread_id: newThreadId })
    }
  } catch (error) {
    console.info('Error sending project to estimator', error)
  }
}

function updateParkData (park, updatedObject) {
  const updated = Object.keys(updatedObject).reduce((acc, key) => {
    const newKey = key.charAt(0).toLowerCase() + key.slice(1)
    acc[newKey] = updatedObject[key]
    return acc
  }, {})
  const updatedId = updated.id

  const index = park.objects.findIndex(obj => obj.id === updatedId)

  if (index !== -1) {
    park.objects[index] = updated
  } else {
    console.error('Object with the specified ID not found.')
  }

  return park
}

function sendMessage (ref, messageText) {
  const message = {
    action: 'ask',
    data: messageText
  }

  postMessage(ref, message)
}

// These events (load, save) are supposed to be on the iframe side, However
// added them here to demonstrate abstracting PS vs UE logic and exposing it
// as a sane API methods that encapsulate unreal logic.

function load (ref, origin, data) {
  const command = 'load_world'

  sendUEMessage({ ref, command, data, origin })
}

function save (ref, origin) {
  const command = 'save_world'
  const data = {}

  sendUEMessage({ ref, command, data, origin })
}

function setObjectMode ({ ref, mode, steps = 1, origin }) {
  const command = 'set_object_mode'
  const data = { mode, steps }

  sendUEMessage({ ref, command, data, origin })
}

function tapeMeasure ({ ref, state, origin }) {
  const command = 'tape_measure'
  const data = { activate: state }

  sendUEMessage({ ref, command, data, origin })
}

function switchCameraMode (ref, mode, origin) {
  const command = 'camera_mode'
  const data = { mode }

  sendUEMessage({ ref, command, data, origin })
}

function displayLayer ({ ref, layer, show, origin }) {
  const command = 'display_layer'
  const data = { layer, show }

  sendUEMessage({ ref, command, data, origin })
}

function multiSelect ({ ref, mode, origin }) {
  const command = 'multi_select'
  const data = { mode }

  sendUEMessage({ ref, command, data, origin })
}

function sendUEMessage ({ ref, command, data, origin }) {
  const message = {
    messageType: 'PS',
    messageId: 'UE',
    data: {
      data: {
        command,
        data
      }
    }
  }

  console.log('PARENT --> UE', message)
  postMessage(ref, message, origin)
}

function postMessage (ref, message, origin = DEFAULT_ORIGIN) {
  const target = ref?.current
  if (!target) return

  target?.contentWindow?.postMessage(message, origin)
}

const unreal = {
  postMessage,
  sendUEMessage,
  handleMessages,
  sendMessage,
  setObjectMode,
  switchCameraMode,
  displayLayer,
  tapeMeasure,
  multiSelect,
  load,
  save
}

export default unreal
